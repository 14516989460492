.filter {
  margin-bottom: 20px;
  position: relative;

  &-container {
    padding-bottom: 0;
  }

  &-header {
    font-weight: 700;
    margin-bottom: 10px;
    display: block;
  }

  &-main {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    &-input {
      margin-right: 20px;
      margin-bottom: 20px;

      &.-name {
        flex: 0 0 200px;
        max-width: 200px;
      }
    }

    &-checkbox {
      width: 20px;
      margin-right: 8px;
      box-shadow: none !important;
      cursor: pointer;
    }
  }

  &-actions {
    position: absolute;
    right: 20px;
    bottom: 24px;

    &-reset {
      cursor: pointer;
      opacity: .6;
    }

    &-apply {
      cursor: pointer;
      margin-left: 10px;
      font-weight: 700;
    }
  }

}
