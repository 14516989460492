/*.FileLoad-Preview-Close {
  display: none;
}

.FileLoad-PreviewImg,
.FileLoad-PreviewDoc {
  width: 100%;
  margin: 0;

  &:hover {
    .FileLoad-Preview-Close {
      display: inline-block;
    }
  }
}


.FileInput {
  width: 142px;
  height: 142px;
  display: inline-block;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  &-Img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-Field {
    display: none;
  }

  &:hover:after {
    background-color: gold;
    //background-color: fade(@brand-darkest, 70%);
  }

  &_block {
    display: block;
  }

  &_size_md {
    width: 110px;
    height: 110px;
  }

  &_size_sm {
    width: 68px;
    height: 68px;
  }

  &_error {
    border: 2px solid red;
  }

  &_empty {
    &:after {
      content: attr(data-placeholder);
      width: 100%;
      height: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 20px;
      color: black;
      display: flex;
      align-items: flex-end;
      text-align: center;
    }
  }
}*/

.FileInput {
  width: 142px;
  height: 142px;
  display: inline-block;
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  &-Img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &-empty {
    position: relative;
    background-color: #415058;

    &:before, &:after {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      content: '';
      width: 2px;
      height: 50px;
      background-color: #FBFBFB;
    }

    &:after {
      transform: rotate(90deg);
    }
  }

  &-Field {
    display: none;
  }

  &-Close {
    width: 21px;
    height: 21px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    background: #415058 url("../../../static/img/close-small-white.png") center no-repeat;
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 20;
  }

}

.Input_Preview {
  &-Dish {
    background: lightblue url("../../../static/img/female_cook.png") center no-repeat;
    background-size: 42px;
  }
}
