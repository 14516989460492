.FileLoad {
  width: 160px;
  height: 160px;
  min-width: 100%;
  max-height: 100%;
  margin-right: 20px;
  padding: 0;
  position: relative;

  &:hover {
    box-shadow: 0 0 25px 0 rgba(152, 169, 179, 0.25);
    cursor: pointer;
  }

  &_size_sm {
    width: 110px;
    height: 110px;
  }
}

.SliderContent {
  height: 100%;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #415058;
  z-index: 500;
}