.page {
  &-header {
    height: 60px;
    width: 100%;
    background-color: $white;
    border-bottom: 1px solid rgb(231, 231, 231);
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    &.-action {
      justify-content: space-between;
    }

    &-title {
      margin: 0 30px;
      font-weight: bold;

      a, a:hover, a:focus, a:active, a:visited {
        color: inherit;
        text-decoration: inherit;
      }
    }

    &-action {
      margin: 0 30px;
      display: flex;
      align-items: center;

      .btn-delete {
        margin-right: 100px;
      }
    }
  }

  &-container {
    padding: 0 30px 30px;

    &-card {
      &.-full {
        min-height: calc(100vh - 190px);
      }

      &-main {
        flex-grow: 1;
        flex-shrink: 1;
        padding: 20px;
      }
    }
  }
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: $white;
  background-clip: border-box;
  border: 1px solid rgba(51, 51, 51, 0.125);
  border-radius: .25rem;

  &-body {
    flex: 1 1 auto;
    padding: 1.25rem;
  }
}

.form {
  &-control {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: .875rem;
    line-height: 1.5;
    color: #495057;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: background-color .15s ease-in-out, box-shadow .15s ease-in-out;
  }
}


::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background-color: #E0E0E0;
}

::-webkit-scrollbar-track {
  border-radius: 7px;
}
